const Navigation = [
  /*  */
  {
    name: "ARCHITECTURE",
    href: "/",
    submenu: [
      { name: "All Projects", href: "/projects" },
      { name: "Master Planning", href: "/projects/masterplanning" },
      { name: "Hospitality", href: "/projects/hospitality" },
      { name: "Institution", href: "/projects/institutional" },
      { name: "Residential", href: "/projects/residential" },
      { name: "Commercial", href: "/projects/commercial" },
      { name: "Interiors", href: "/projects/interiors" },
    ],
  },
  {
    name: "RESEARCH",
    href: "/research",
    submenu: [
      { name: "Real", href: "/" },
      { name: "⁠Adaptive Habitats", href: "/" },
      { name: "⁠REAL 1.0", href: "/" },
      { name: "⁠REAL 2.0", href: "/" },
    ],
  },
  {
    name: "INNOVATION",
    href: "/innovation",
    submenu: [
      { name: "All Projects", href: "/projects/innovation" },
      {
        name: "Community Health Facility",
        href: "/projects/innovation/community-medical-facility",
      },
      { name: "sChoker", href: "/projects/innovation/the-schoker" },
      {
        name: "Aam Aadmi Mohalla Clinic",
        href: "/projects/innovation/aam-aadmi-mohalla-clinic",
      },
      {
        name: "Automated Mobility",
        href: "/projects/innovation/automated-mobility",
      },
    ],
  },
  {
    name: "ATELIER",
    href: "/",
    submenu: [
      {
        name: "About",
        href: "/about-us",
      },
      { name: "People", href: "/people" },
      { name: "News", href: "/news" },
      { name: "Contact", href: "/contact" },
    ],
  },
];

export default Navigation;
