"use client";
import { useState } from "react";
import Slider from "react-slick";
import Image from "next/image";
import HeaderHome from "@/components/HeaderHome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeroSlider() {
  const [textColor, setTextColor] = useState("white");
  const [logoSrc, setLogoSrc] = useState("/images/aria-white-logo.png"); // Default logo
  const [iconColor, setIconColor] = useState("white");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
    afterChange: (index) => {
      const imgElement = document.querySelectorAll(".slick-slide img")[index];
      if (imgElement) {
        const brightness = calculateImageBrightness(imgElement);
        setTextColor(brightness < 128 ? "white" : "black");
        setLogoSrc(
          brightness < 128
            ? "/images/aria-white-logo.png"
            : "/images/aria-black-logo.png"
        );
        setIconColor(brightness < 128 ? "white" : "black");
      }
    },
  };

  const calculateImageBrightness = (imgElement) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = imgElement.naturalWidth;
    canvas.height = imgElement.naturalHeight;
    context.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    let colorSum = 0;
    const length = data.length;

    for (let i = 0; i < length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      const avg = (r + g + b) / 3;
      colorSum += avg;
    }

    return colorSum / (length / 4);
  };

  return (
    <>
      <HeaderHome
        textColor={textColor}
        logoSrc={logoSrc}
        iconColor={iconColor}
      />
      {/* Pass dynamic text color and logo */}
      <section className="relative h-[700px] lg:h-screen overflow-hidden -mt-24">
        <Slider {...settings}>
          <div className="relative h-[700px] lg:h-screen">
            <Image
              src="/images/slider/50.jpg"
              alt="Slide 1"
              fill
              sizes="100vw"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
          <div className="relative h-[700px] lg:h-screen">
            <Image
              src="/images/slider/hospitality-3.jpg"
              alt="Slide 2"
              fill
              sizes="100vw"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
          <div className="relative h-[700px] lg:h-screen">
            <Image
              src="/images/slider/5.jpg"
              alt="Slide 3"
              fill
              sizes="100vw"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
          <div className="relative h-[700px] lg:h-screen">
            <Image
              src="/images/slider/80.jpg"
              alt="Slide 4"
              fill
              sizes="100vw"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
          <div className="relative h-[700px] lg:h-screen">
            <Image
              src="/images/slider/parth-republic-4.jpg"
              alt="Slide 4"
              fill
              sizes="100vw"
              className="absolute top-0 left-0 object-cover"
            />
          </div>
        </Slider>
      </section>
    </>
  );
}
