"use client";
import Image from "next/image";
import Link from "next/link";

const ProjectGrid = ({ projects }) => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl text-center my-8">PROJECTS</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-1">
        {projects.map((project, index) => (
          <Link key={index} href={`/projects${project.slug}`} passHref>
            <div className="relative group cursor-pointer">
              {/* "New" Badge */}
              {project.isNew && (
                <div className="absolute top-2 left-2 bg-white text-gray-900 text-xs font-semibold px-2 py-1 z-10">
                  NEW
                </div>
              )}

              {/* Image with hover zoom effect */}
              <div className="relative overflow-hidden">
                <Image
                  src={project.imageSrc}
                  alt={project.title}
                  width={500}
                  height={300}
                  className="object-cover w-full h-64 transform transition-transform duration-300 group-hover:scale-110"
                />
                {/* Overlay */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
              </div>

              {/* Project Info */}
              <div className="mt-4">
                <div className="flex flex-row items-stretch px-6">
                  <p className="text-gray-500 text-sm flex-1">
                    {project.country}
                  </p>
                  <p className="text-gray-500 text-sm">{project.date}</p>
                </div>
                <h3 className="text-xl font-semibold ml-6 text-black">
                  {project.title}
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectGrid;
