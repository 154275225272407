"use client";
import Navigation from "@/components/Navigation";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ textColor, logoSrc, iconColor }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentLogoSrc, setCurrentLogoSrc] = useState(logoSrc);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
        setCurrentLogoSrc("/images/aria-black-logo.png");
      } else {
        setIsScrolled(false);
        setCurrentLogoSrc(logoSrc);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [logoSrc]);

  const currentTextColor = isScrolled ? "black" : textColor;

  const toggleSearchBar = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <header
      className={classNames(
        "sticky top-0 z-[999] w-full transition-colors duration-300 tracking-wider",
        isScrolled ? "bg-white shadow-sm" : "bg-transparent"
      )}
      style={{ color: currentTextColor }}
    >
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto">
              <div className="relative flex items-center justify-between h-24">
                {/* Mobile Menu Button */}
                <div className="absolute rounded-md  mx-4 bg-white left-0  items-stretch sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                {/* Logo Section */}
                <div className="flex-1 flex items-center justify-center sm:items-center sm:justify-start">
                  <div className="flex-1">
                    <Link href="/">
                      <Image
                        src={currentLogoSrc}
                        className="object-cover mx-auto lg:mx-0"
                        alt="logo"
                        width={150}
                        height={100}
                      />
                    </Link>
                  </div>

                  {/* Desktop Navigation */}
                  <div className="hidden sm:block sm:ml-6 self-center flex-shrink-0">
                    <div className="flex space-x-10 justify-center">
                      {Navigation.map((item) =>
                        item.submenu ? (
                          <div key={item.name} className="relative group">
                            <Link
                              href={item.href}
                              className=""
                              style={{ color: currentTextColor }}
                            >
                              {item.name}
                            </Link>
                            {/* Dropdown for Desktop */}
                            <div className="absolute hidden group-hover:block bg-white text-black py-2 w-44 shadow-lg rounded-lg z-10 overflow-hidden">
                              {item.submenu.map((submenuItem) => (
                                <Link
                                  key={submenuItem.name}
                                  href={submenuItem.href}
                                  className="block px-4 py-2 hover:bg-gray-100 text-black"
                                >
                                  {submenuItem.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="uppercase text-base font-serif px-3 pb-2 rounded-md transition-colors"
                            style={{ color: currentTextColor }}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>

                  {/* Search and User Icons */}
                  {/* <div className="flex space-x-4">
                    <button
                      onClick={toggleSearchBar}
                      className={`p-2 ${iconColor}`}
                    >
                      <CiSearch className="text-2xl cursor-pointer hover:text-gray-900" />
                    </button>
                    {isSearchOpen && (
                      <div className="absolute top-16 left-0 right-0 bg-white border border-gray-300 p-2">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="w-full p-2 border-none outline-none"
                        />
                      </div>
                    )}
                    <CiUser
                      className={`text-2xl self-center cursor-pointer hover:text-gray-900 ${iconColor}`}
                    />
                  </div> */}
                </div>
              </div>
            </div>

            {/* Mobile Navigation */}
            <Disclosure.Panel className="sm:hidden bg-white">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {Navigation.map((item) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div" className="relative">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                            {item.name}
                          </Disclosure.Button>
                          <Disclosure.Panel className="pl-6">
                            {item.submenu.map((submenuItem) => (
                              <Link
                                key={submenuItem.name}
                                href={submenuItem.href}
                                className="block px-3 py-2 text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                              >
                                {submenuItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
